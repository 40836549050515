.hero-image {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url('../../assets/img/hero.jpg') center/cover no-repeat;
}

.hero-title {
  text-align: center;
  font-size: 2rem;
}

@media(min-width: 1024px) {
  .hero-image {
    font-size: 2.5rem;
  }
}

.shop-now-btn {
  text-align: center;
}