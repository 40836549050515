.size-row {
  column-count: 2;
  cursor: pointer;
  padding: .2rem;
  margin-bottom: .5rem;
}

.size-row-selected {
    background-color: antiquewhite;
  }

.column {
    padding-left: .2rem;
    padding-right: .2rem;
}