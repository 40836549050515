@font-face {
  font-family: 'Pacifico-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Pacifico-Regular'), local('Pacifico-Regular'),
       url('../../assets/fonts/Pacifico-Regular.ttf') format('truetype'), /* Safari, Android, iOS */

}

.nav-menu {
    display: flex;
    padding: 1rem;
  
    li {
      a {
        text-decoration: none;
        color: #1f001fff;
        font-size: 1.5rem;
        &:focus, &:hover, &:visited {
          color: #1f001fff;
        }
      }
  
      a:visited {
        color: #1f001fff;
      }
    }

  
    // .logo {
    //   font-size: 2rem;
    //   font-family: 'pacifico-regular';
    //   // color: #1f001fff;
    // }

    .logo a {
      text-decoration: none;
      color: #1f001fff;
    }
  
    ul {
      list-style-type: none;
      margin: 0;
      margin-left: auto;
  
      li {
        display: inline-block;
        margin: 0 1rem;
        cursor: pointer;
      }
    }
  }

.banner-bg {
  background: url('../../assets/img/floral_yellow.png') repeat;
}