/* The Modal (background) */
.modal {
    // display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100vh; /* Full height */
    // overflow: auto;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  .modal-show {
    display: block;
  }
  
  .modal-hide {
    display: none;
  }

  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    height: 70vh;
  }
  
  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  .modal-scroll-text {
      height: 80%;
      overflow-y: scroll;
      margin-bottom: 1rem;
  }

  .modal-header {
      height: 10%;
      display: block;
  }

  .modal-header > * {
    display: inline-block;
  }

  .modal-footer {
    height: 10%;
    display: block;
  }
