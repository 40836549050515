@charset "utf-8";

// Import only what you need from Bulma
@import '../node_modules/bulma/sass/utilities/_all.sass';
@import '../node_modules/bulma/sass/elements/button.sass';
@import '../node_modules/bulma/sass/elements/container.sass';
@import '../node_modules/bulma/sass/layout/hero.sass';


.app {
    position: relative;
    min-height: 100vh;
  }
  
  img {
    width: 100%;
  }
  
  .nomad-container {
    width: 60%;
    margin: 0 auto;
  }
  
  #shop-now {
    font-weight: 600; 
    &:hover {
      background-color: #606060;
    }
  }
  
  // Featured products section
  .featured-collection {
    margin-top: 6rem;
  
    .featured-section-title {
      font-size: 2rem;
    }
  
    .featured-image {
      cursor: pointer;
    }
  
    @media(min-width: 1024px) {
      .products {
        display: flex;
        justify-content: space-between;
        padding: 5px;
  
        .featured-product {
          margin: 10px;
        }
      }
    }
  }
  
  // single product page 
  .name-price {
    p {
      font-size: 1.2rem;
      font-weight: bold;
    }
  
    h3 {
      font-size: 1rem;
    }
  }
  
  //black button styles
  .nomad-btn {
    display: block;
    width: 100%;
    line-height: 1.4;
    padding-left: 5px;
    padding-right: 5px;
    white-space: normal;
    margin-top: 0;
    margin-bottom: 10px;
    min-height: 44px;
  }
  
  //input styles
  .nomad-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 1rem;
  }

  input[type=checkbox] {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  form {
    padding: 2rem;
    text-align: center;
    input[type=text],
    input[type=email],
    input[type=password]{
      width: 60%;
    }
  
    .submit-btn {
      display: flex;
      justify-content: center;
    }
  
    .submit {
      width: 200px;
    }
  }
  
  .form-error {
    border: 1px solid red;
  }
  
  ::placeholder {
    color: #606060;
    font-size: 16px;
  }
  
  #btn-white-outline {
    border: 1px solid grey;
    margin-bottom: 1rem;
  }
  #buy-it-now-btn {
    &:hover {
      background-color: #606060;
    }
  }
  
  @media(min-width: 1024px) {
    form {
      width: 60%;
      margin: 0 auto;
  
      input[type=text],
      input[type=email],
      input[type=password]{
        width: 65%;
      } 
    }
  }
  
  .error {
    border: 1px solid red;
  }
  
  .error-message {
    color: red;
    font-size: 1.5rem;
  }

  .logo {
    font-size: 2rem;
    font-family: 'pacifico-regular';
    color: #1f001fff;
  }